import { NavigateFunction } from "react-router"
require('dotenv').config()

function signOut(navigate: NavigateFunction) {
    localStorage.removeItem('temitope');
    navigate('/');
};

function filenameOnly(path: string): string {
    const match = path.match(/([^\/]+)(?=\.\w+$)/);
    const filename = match ? match[1] : 'image';
    return filename
};

function getApiUrl(): string {
    const apiUrl = process.env.REACT_APP_API_URL || 'https://skk.pegamento.nl/api';
    return apiUrl
}

export { signOut, filenameOnly, getApiUrl };
