import React, { useContext, useState, useEffect, useRef, CSSProperties } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppContext from "./components/hooks/createContext";
import { signOut, filenameOnly, getApiUrl } from "./components/helpers/authHelper"
import { fetchToken } from "./Auth"

import { drawCrosshair } from "./components/helpers/scaleHelper";

import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Container,
  Grid,
  Box,
  Card,
  IconButton
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { table } from 'console';


// Assuming BoundingBox and BoundingBoxInfo types are already defined
interface BoundingBoxInfo {
  id: string;
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
  length: number;
  width: number;
  type: string;
  turning: boolean;
  frameId?: string;
  panes?: BoundingBoxInfo[];
  nested?: boolean;
  area: number;
  category?: string;
}

const doorAndWindowOptions = [
  "Deur MPS",
  "Deur Z MPS",
  "Stolpdeur",
  "Stolpraam",
  "DKR",
  "Draairaam",
  "Valraam",
  "Uitzetraam",
  "Hardgl uitzetraam",
  "Dakraam",
  "Vent rooster",
  "Schuifraam",
  "Schuifdeur",
  "Schuifraam >1 Schuivend",
  "Schuifdeur >1 Schuivend",
  "PH 2",
  "PH 4"
];

const typeDisplayMapping: { [key: string]: string } = {
  frames: 'Kozijn',
  panes: 'Glas',
  doors: 'Deur',
  ventilation: 'Ventilatierooster',
  panel: 'Paneel'
};

const EditCoords = () => {
  const location = useLocation();
  const { imageUrl, boundingBoxes }: { imageUrl: string; boundingBoxes: BoundingBoxInfo[] } = location.state || {};
  const imageRef = useRef<HTMLImageElement | null>(null); // Ref to store the loaded image
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const zoomCanvasRef = useRef<HTMLCanvasElement>(null);
  const [selectedBoxIndex, setSelectedBoxIndex] = useState<number | null>(null);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [resizeDirection, setResizeDirection] = useState<string | null>(null);
  const [mouseStart, setMouseStart] = useState<{ x: number; y: number } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isNestingCalculated, setIsNestingCalculated] = useState(false);
  const [showZoomCanvas, setShowZoomCanvas] = useState(false);
  const [clientHeight, setclientHeight] = useState(100);

  const [currentBoundingBoxDetails, setCurrentBoundingBoxDetails] = useState<BoundingBoxInfo[]>(boundingBoxes || []);
  const [nestedBoundingBoxDetails, setnestedBoundingBoxDetails] = useState<BoundingBoxInfo[]>(boundingBoxes || []);

  const {

    scalingFactor: [scalingFactor],
    widthScale: [widthScale],
    heightScale: [heightScale]
  } = useContext(AppContext)!;

  const [editType, setEditType] = useState<string | null>(null);


  const generateUniqueId = () => Date.now() + "_" + Math.random().toString(36).substring(2, 9);

  const cornerRadius = 5;

  const colorMap: { [key: string]: string } = {
    frames: 'rgba(232, 96, 96, 0.70)',
    panes: 'rgba(122, 185, 228, 0.70)',
    doors: 'rgba(87, 190, 98, 0.70)',
    ventilation: 'rgba(87, 98, 190, 0.70)',
    panel: 'rgba(190, 190, 87, 0.70)'
  };

  const navigate = useNavigate();
  const handleSignOut = () => {
    signOut(navigate);
  };

  const addNewRectangle = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const effectiveWidthScale = widthScale || 1;
    const effectiveHeightScale = heightScale || 1;

    const fixedWidth = canvas.width / 5;
    const fixedHeight = canvas.height / 5;


    const centerX = (canvas.width / 2)
    const centerY = (canvas.height / 2)

    const xMin = centerX - fixedWidth / 2;
    const yMin = centerY - fixedHeight / 2;

    const real_width = fixedWidth * effectiveWidthScale
    const real_height = fixedHeight * effectiveHeightScale

    const newRectangle = {
      id: generateUniqueId(),
      xMin,
      yMin,
      xMax: xMin + fixedWidth,
      yMax: yMin + fixedHeight,
      width: real_width,
      length: real_height,
      type: "frames",
      turning: false,
      area: fixedWidth * fixedHeight
    };

    setCurrentBoundingBoxDetails(prevDetails => [...prevDetails, newRectangle]);
  };


  const drawBoundingBoxes = (ctx: CanvasRenderingContext2D, boxes: BoundingBoxInfo[]) => {
    boxes.forEach((box, index) => {
      const isEditTypeMatching = editType === box.type;
      const color = box.type in colorMap ? colorMap[box.type] : 'red';
      const fillColor = isEditTypeMatching ? color : 'red';


      ctx.strokeStyle = selectedBoxIndex === index ? '#ffff66' : color;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.rect(box.xMin, box.yMin, box.xMax - box.xMin, box.yMax - box.yMin);
      ctx.stroke();

      if (isEditTypeMatching) {
        ctx.fillStyle = fillColor;
        ctx.fill();
      }

      const drawCornerSquare = (x: number, y: number, size: number) => {
        ctx.beginPath();
        // Adjust x and y to center the square around the corner point
        ctx.rect(x - size / 2, y - size / 2, size, size);
        ctx.fillStyle = color;
        ctx.fill();
      };

      const cornerSize = 15;

      drawCornerSquare(box.xMin, box.yMin, cornerSize);
      drawCornerSquare(box.xMax, box.yMin, cornerSize);
      drawCornerSquare(box.xMin, box.yMax, cornerSize);
      drawCornerSquare(box.xMax, box.yMax, cornerSize);

      if (!isNestingCalculated) {

        // Text styling
        ctx.font = 'bold 16px Arial';
        ctx.fillStyle = '#000000'; // Black text color
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Define the circle radius
        const circleRadius = 12;

        let textX, textY;
        if (box.type === 'panes') {
          // Position inside the rectangle
          textX = box.xMin + (box.xMax - box.xMin) / 2;
          textY = box.yMin + (box.yMax - box.yMin) / 2;
        } else {
          // Position outside to the right of the rectangle
          textX = box.xMax + circleRadius * 2;
          textY = box.yMin + 20;
          ctx.textAlign = 'center';
        }

        // Draw the circle for the index number
        ctx.beginPath();
        ctx.arc(textX, textY, circleRadius, 0, 2 * Math.PI);
        ctx.fillStyle = color;
        ctx.fill();
        ctx.strokeStyle = '#000000'; // Black border for the circle
        ctx.stroke();

        // Draw the index number
        ctx.fillStyle = '#000000'; // Set text color to black
        ctx.fillText((index + 1).toString(), textX, textY);

      }

    });

  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !imageUrl) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      drawBoundingBoxes(ctx, currentBoundingBoxDetails);
      imageRef.current = img; // Store the loaded image in the ref

      if (!canvasRef.current) return;
      setclientHeight(canvasRef.current.clientHeight)
    };


  }, [imageUrl, currentBoundingBoxDetails, selectedBoxIndex, isNestingCalculated]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();

    const handleMouseDown = (e: MouseEvent) => {
      const x = (e.clientX - rect.left) * (canvas.width / canvas.clientWidth);
      const y = (e.clientY - rect.top) * (canvas.height / canvas.clientHeight);
      setMouseStart({ x, y });
      let dragInitiated = false;

      currentBoundingBoxDetails.forEach((box, index) => {

        if (box.type === editType) {
          const insideBox = x > box.xMin && x < box.xMax && y > box.yMin && y < box.yMax;

          // Add a buffer zone to avoid dragging when clicking near the corners for resizing
          const bufferZone = cornerRadius * 2;
          const insideResizeZone =
            x <= box.xMin + bufferZone || x >= box.xMax - bufferZone ||
            y <= box.yMin + bufferZone || y >= box.yMax - bufferZone;

          if (insideBox && !insideResizeZone) {
            setSelectedBoxIndex(index);
            setIsDragging(true);
            dragInitiated = true; // Flag to indicate that dragging is initiated
          }
        }
      });

      if (!dragInitiated) {
        currentBoundingBoxDetails.forEach((box, index) => {

          if (editType === null || box.type !== editType) {
            return;
          }
          // Function to check if mouse is near a corner circle
          const isMouseNearCorner = (cornerX: number, cornerY: number) => {
            const distance = Math.hypot(x - cornerX, y - cornerY);
            return distance <= cornerRadius;
          };

          // Check if the mouse is near any of the four corners
          const nearTopLeft = isMouseNearCorner(box.xMin, box.yMin);
          const nearTopRight = isMouseNearCorner(box.xMax, box.yMin);
          const nearBottomLeft = isMouseNearCorner(box.xMin, box.yMax);
          const nearBottomRight = isMouseNearCorner(box.xMax, box.yMax);

          if (nearTopLeft || nearTopRight || nearBottomLeft || nearBottomRight) {
            setSelectedBoxIndex(index);
            setIsResizing(true); // Initiate resizing
            // Determine resize direction based on which corner is nearest
            if (nearTopLeft) setResizeDirection('topLeft');
            if (nearTopRight) setResizeDirection('topRight');
            if (nearBottomLeft) setResizeDirection('bottomLeft');
            if (nearBottomRight) setResizeDirection('bottomRight');
            setIsDragging(false); // Ensure we're not dragging
          }
          // If not near any corner circles, check if inside the box for potential dragging
          else if (x > box.xMin && x < box.xMax && y > box.yMin && y < box.yMax) {
            setSelectedBoxIndex(index);
            setIsDragging(true); // Start dragging
            setIsResizing(false); // Ensure we're not resizing
          }
        });
      }
    };

    const handleZoomLeave = () => {
      if (zoomCanvasRef.current) {
        zoomCanvasRef.current.style.display = "none";
      }
    };

    const handleZoom = (e: MouseEvent) => {
      if (!canvas) return;
      var x = 0;
      var y = 0;
      var zoomSizeX = 0;
      var zoomSizeY = 0;
      const zoomDestSizeX = 300;
      const zoomDestSizeY = 300;
      const zoomCtx = zoomCanvasRef.current?.getContext('2d');
      if (zoomCtx && canvas && zoomCanvasRef.current) {
        const rect = canvas.getBoundingClientRect();
        zoomSizeX = canvas.width / 4;
        zoomSizeY = canvas.width / 4;
        x = e.clientX - rect.left;
        y = e.clientY - rect.top;
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        const zoomX = (x) * scaleX - (zoomSizeX / 2);
        const zoomY = (y) * scaleY - (zoomSizeY / 2);

        zoomCtx.fillStyle = "white";
        zoomCtx.fillRect(0, 0, zoomCanvasRef.current.width, zoomCanvasRef.current.height);
        zoomCtx.drawImage(canvas, zoomX, zoomY, zoomSizeX, zoomSizeY, 0, 0, zoomDestSizeX, zoomDestSizeY);

        if (x + zoomCanvasRef.current.width > rect.width) {
          x = rect.width - zoomCanvasRef.current.width
        }

        if (y + zoomCanvasRef.current.height > rect.height) {
          y = rect.height - zoomCanvasRef.current.height
        }
        zoomCanvasRef.current.style.top = `${y}px`;
        zoomCanvasRef.current.style.left = `${x}px`;
        zoomCanvasRef.current.style.display = "block";
        drawCrosshair(zoomCtx, zoomDestSizeX / 2, zoomDestSizeY / 2)

      }
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (!mouseStart || selectedBoxIndex === null) return;

      const rect = canvas.getBoundingClientRect();
      const x = (e.clientX - rect.left) * (canvas.width / canvas.clientWidth);
      const y = (e.clientY - rect.top) * (canvas.height / canvas.clientHeight);
      const dx = x - mouseStart.x;
      const dy = y - mouseStart.y;
      const cornerSize = 10; // Sensitivity area around corners

      const ctx = canvas.getContext('2d');
      if (!ctx || !imageRef.current) return;

      let cursorStyle = 'default'; // Default cursor style

      const selectedBox = currentBoundingBoxDetails[selectedBoxIndex];

      if (editType === null || selectedBox.type !== editType) {
        return;
      }

      if (isDragging) {

        const box = currentBoundingBoxDetails[selectedBoxIndex];
        let { xMin, yMin, xMax, yMax } = box;
        const updatedBox = { ...box, xMin: xMin + dx, xMax: xMax + dx, yMin: yMin + dy, yMax: yMax + dy };
        const updatedBoundingBoxes = currentBoundingBoxDetails.map((box, index) =>
          index === selectedBoxIndex ? updatedBox : box);

        setCurrentBoundingBoxDetails(updatedBoundingBoxes);

      }

      currentBoundingBoxDetails.forEach((box, index) => {
        if (selectedBoxIndex === index) {
          // Check if the mouse is near any of the corners of the selected box
          const nearTopLeft = Math.hypot(x - box.xMin, y - box.yMin) < cornerSize;
          const nearTopRight = Math.hypot(x - box.xMax, y - box.yMin) < cornerSize;
          const nearBottomLeft = Math.hypot(x - box.xMin, y - box.yMax) < cornerSize;
          const nearBottomRight = Math.hypot(x - box.xMax, y - box.yMax) < cornerSize;

          if (nearTopLeft || nearTopRight || nearBottomLeft || nearBottomRight) {
            cursorStyle = 'nwse-resize'; // Diagonal resize cursor
          }
        }
      });

      canvas.style.cursor = cursorStyle;

      if (!isResizing || selectedBoxIndex === null || !mouseStart || !resizeDirection) return;

      const box = currentBoundingBoxDetails[selectedBoxIndex];

      let { xMin, yMin, xMax, yMax } = box;

      // Calculate new dimensions based on resize direction
      switch (resizeDirection) {
        case 'bottomRight':
          xMax = Math.max(xMin + 1, xMax + dx); // Ensure xMax is greater than xMin
          yMax = Math.max(yMin + 1, yMax + dy); // Ensure yMax is greater than yMin
          break;
        case 'bottomLeft':
          xMin = Math.min(xMax - 1, xMin + dx); // Ensure xMin is less than xMax
          yMax = Math.max(yMin + 1, yMax + dy); // Ensure yMax is greater than yMin
          break;
        case 'topRight':
          xMax = Math.max(xMin + 1, xMax + dx); // Ensure xMax is greater than xMin
          yMin = Math.min(yMax - 1, yMin + dy); // Ensure yMin is less than yMax
          break;
        case 'topLeft':
          xMin = Math.min(xMax - 1, xMin + dx); // Ensure xMin is less than xMax
          yMin = Math.min(yMax - 1, yMin + dy); // Ensure yMin is less than yMax
          break;
      }

      const effectiveWidthScale = widthScale || 1;
      const effectiveHeightScale = heightScale || 1;
  
      const updatedBox = { ...box, xMin: xMin, xMax: xMax, yMin: yMin, yMax: yMax, width: (xMax - xMin) * effectiveWidthScale, length: (yMax - yMin) * effectiveHeightScale };

      const updatedBoundingBoxes = currentBoundingBoxDetails.map((box, index) =>
        index === selectedBoxIndex ? updatedBox : box);

      setCurrentBoundingBoxDetails(updatedBoundingBoxes);

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(imageRef.current, 0, 0);

      // Redraw all bounding boxes (optimized approach would be to redraw only the necessary ones)
      drawBoundingBoxes(ctx, updatedBoundingBoxes);

      setMouseStart({ x, y }); // Update start position for continuous resizing
    };

    const handleMouseUp = () => {

      if (isDragging) {
        setIsDragging(false);
        setSelectedBoxIndex(null); // Optionally reset selected index
      }
      if (isResizing) {
        setIsResizing(false);
        setSelectedBoxIndex(null);
        setResizeDirection(null);
        // You might want to update the boundingBoxDetails state here to reflect the changes
      }
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleZoom);
    canvas.addEventListener('mouseleave', handleZoomLeave);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleZoom);
      canvas.removeEventListener('mouseleave', handleZoomLeave);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, selectedBoxIndex, mouseStart, resizeDirection, boundingBoxes]);

  const handleDeleteRow = (id: string) => {
    setCurrentBoundingBoxDetails(current =>
      current.filter(item => item.id !== id));
  };

  const handleTypeChange = (index: number, event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    // Create a new array with the updated details
    const updatedDetails = currentBoundingBoxDetails.map((detail, detailIndex) => {
      if (index === detailIndex) {
        // Update the type of the rectangle at the specified index
        return { ...detail, type: newType };
      }
      return detail; // Return the unmodified detail for other indices
    });

    // Update the state with the new details array
    setCurrentBoundingBoxDetails(updatedDetails);
  };

  const calculateNesting = () => {
    // Assuming 'currentBoundingBoxDetails' is your array of all boxes
    let updatedDetails = currentBoundingBoxDetails.map(box => ({
      ...box,
      id: box.id || generateUniqueId(),
      nested: false, // Add a 'nested' property to track if a pane is nested
    }));

    // Assign IDs and nest pan`es
    updatedDetails.forEach(frame => {
      frame.panes = [];
    });

    updatedDetails.sort((a, b) => (a.width * a.length) - (b.width * b.length));

    updatedDetails.forEach(pane => {
      const parentFrame = updatedDetails.find(frame =>
        pane.id !== frame.id &&
        pane.xMin >= frame.xMin && pane.xMax <= frame.xMax &&
        pane.yMin >= frame.yMin && pane.yMax <= frame.yMax);

      if (parentFrame) {
        if (!parentFrame.panes) {
          parentFrame.panes = [];
        }

        if (!pane.nested) {
          parentFrame.panes.push(pane);
          pane.frameId = parentFrame.id;
          pane.nested = true;
        }
      }
    });

    updatedDetails.forEach(box => {
      // Calculate the total area of nested panes
      const totalPaneArea = box.panes?.reduce((sum, pane) => sum + ((pane.width * pane.length) / 1000000), 0) || 0;
      // Subtract the total pane area from the frame/door area
      box.area = ((box.width * box.length) / 1000000 - totalPaneArea);

    });


    setnestedBoundingBoxDetails(updatedDetails);
  };

  const handleNextClick = () => {

    calculateNesting();
    setIsNestingCalculated(true);

    const canvas = canvasRef.current;

    if (canvas) {
      canvas.style.pointerEvents = 'none';
    }
  };

  const handleBackClick = () => {

    if (isNestingCalculated) {
      setIsNestingCalculated(false);

      // Re-enable editing features by making the canvas interactive again
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.style.pointerEvents = 'auto';
      }
    }
    else {
      navigate(-1);
    }

  };

  const handleStartOver = () => {
    navigate('/uploadpage')
  }

  const handleCategoryChange = (id: string, newCategory: string) => {
    const updatedDetails = currentBoundingBoxDetails.map(detail => {
      if (detail.id === id) {
        return { ...detail, category: newCategory };
      }
      return detail;
    });
    setCurrentBoundingBoxDetails(updatedDetails);
  };

  const handleTurningChange = (index: number, isTurning: boolean) => {
    const updatedDetails = [...currentBoundingBoxDetails];
    updatedDetails[index].turning = isTurning;
    setCurrentBoundingBoxDetails(updatedDetails);
  };

  const handleEditClick = (type: string) => {
    setEditType(prevType => prevType === type ? null : type);
  };

  const downloadCsv = (data: BoundingBoxInfo[], filename: string = 'data.csv') => {
    let csvRows = [
      'Element;Kozijn;Nummer;Hoogte (mm);Breedte (mm);Draaiend;Deur;Raamtype;Opp(m2)'
    ];

    const isDoor = (type: string) => type.toLowerCase().includes('door');

    const processItem = (item: BoundingBoxInfo, path: string) => {
      const elementType = item.type === 'doors' ? 'Deur' :
        item.type === 'frames' ? 'Kozijn' :
          item.type === 'panel' ? 'Paneel' :
            item.type === 'ventilation' ? 'Ventilatierooster' :
              item.type === 'panes' ? 'Glas' : 'Other';

      const rowForFrameOrPane = [
        elementType,
        item.type === 'frames' ? 'Ja' : 'Nee',
        path,
        Math.round(item.length),
        Math.round(item.width),
        item.turning ? "Ja" : "Nee",
        isDoor(item.type) ? "Ja" : "Nee",
        item.category ?? '',
        (item.area ?? 0).toFixed(2)
      ].join(';');

      csvRows.push(rowForFrameOrPane);

      // Recursively process nested panes
      if (item.panes && item.panes.length > 0) {
        item.panes.forEach((pane, index) => {
          // Construct new path for nested items
          const newPath = `${path}.${index + 1}`;
          processItem(pane, newPath);
        });
      }
    };

    // Start processing each item
    let topLevelIndex = 1;
    data.forEach((item) => {
      if (!item.nested) { // Process top-level items and assign them new top-level indices
        processItem(item, `${topLevelIndex}`);
        topLevelIndex++;
      }
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Optionally upload the file to a server
    const apiUrl = getApiUrl();
    const formData = new FormData();
    formData.append("file", blob, filename);
    const token = fetchToken();

    fetch(`${apiUrl}/embedding/upload-result`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    }).then(data => {
      console.log('Success:', data);
    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const useStyles = makeStyles((theme) => ({
    table: {
      fontSize: '0.75rem',
      minWidth: 60
    },
    row: {
      height: '24px',
    },
    cell: {
      padding: '3px',
      width: '50px', // Adjust as needed or remove if you prefer auto width
      textAlign: 'center'
    },
    logoutButton: {
      borderRadius: '1px',
      border: '1px solid #D8D8D8',
      background: '#FFF',
      color: '#2189CA',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      marginLeft: '17px',
      textTransform: 'none', // Prevent uppercase transform
      padding: '6px 12px', // Adjust padding as needed
      '& svg': { // Style for the SVG icon
        marginRight: '5px', // Space between icon and text
      },
    },
    card: {
      margin: '0',
      maxWidth: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(6),

    },
    inputForm: {
      textAlign: 'left',
    },
    inputFormPadding: {
      padding: '1rem',
    },
    fixedDropdown: {
      width: '150px',
    },
  }))

  const toggleZoomCanvas = () => {
    setShowZoomCanvas(!showZoomCanvas);
  };

  const classes = useStyles();

  const renderNestedRows = (item: BoundingBoxInfo, path: string): JSX.Element[] => {
    const rows = [
      <TableRow key={item.id}
        onMouseEnter={() => setSelectedBoxIndex(currentBoundingBoxDetails.findIndex(obj => obj.id === item.id))}
        onMouseLeave={() => setSelectedBoxIndex(null)}
        className={classes.row}>
        <TableCell className={classes.cell}>{path}</TableCell>
        <TableCell style={{ color: colorMap[item.type] }} className={classes.cell}>
          {typeDisplayMapping[item.type] || item.type}
        </TableCell>
        <TableCell className={classes.cell}>{Math.round(item.width)}</TableCell>
        <TableCell className={classes.cell}>{Math.round(item.length)}</TableCell>
        <TableCell className={classes.cell}>{item.turning ? "Yes" : "No"}</TableCell>
        <TableCell className={classes.cell}>{item.area ? item.area.toFixed(2) : 'N/A'}</TableCell>
        <TableCell className={classes.cell}>{item.category}</TableCell>
        <TableCell className={classes.cell}>
          <IconButton aria-label="delete" style={{ color: 'var(--M3-sys-light-primary, #2189CA)' }} onClick={() => handleDeleteRow(item.id)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ];

    if (item.panes && item.panes.length) {
      item.panes.forEach((nestedItem, index) => {
        const nestedPath = `${path}.${index + 1}`;
        rows.push(...renderNestedRows(nestedItem, nestedPath));
      });
    }

    return rows;
  };

  return (
    <Grid container direction="row">
      <Grid item xs={1}>
        <img src={'../assets/skk.png'} alt="Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
        <a href="https://www.pegamento.nl" target="_blank" rel="noopener noreferrer">
          <img src={'../assets/pegamento.png'} alt="Pegamento Logo" style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '20px' }} />
        </a>
      </Grid>

      <Grid item xs={10}>
        <Card className={classes.card}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
            <Grid item md={6} style={{ position: 'relative', padding: 0 }}>
              <canvas ref={canvasRef} style={{ width: '100%', display: 'block' }}></canvas>
              {showZoomCanvas && (
                <canvas
                  ref={zoomCanvasRef}
                  width="300"
                  height="300"
                  style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
                />
              )}
            </Grid>

            <Grid item md={6} className={classes.inputForm}>
              <Typography variant="h5" gutterBottom className={classes.inputFormPadding}>
                {isNestingCalculated ? "Controleren gegevens" : "Maat aanpassen"}
              </Typography>
              <Typography variant="body1" className={classes.inputFormPadding}>
                {isNestingCalculated
                  ? "Controleer de gegevens. Daarna kunt u de gegevens exporteren."
                  : "Pas nauwkeurig de maat aan van de kozijnen/draaidelen/glasvlakken Als dit niet nodig is ga dan verder naar de volgende stap."}
              </Typography>

              <Paper style={{ maxHeight: `${0.60 * clientHeight}px`, overflow: 'auto', marginRight: '10px' }}>

                {!isNestingCalculated ? (
                  <Table stickyHeader className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>Element</TableCell>
                        <TableCell className={classes.cell}>Type</TableCell>
                        <TableCell className={classes.cell}>Breedte (mm)</TableCell>
                        <TableCell className={classes.cell}>Hoogte (mm)</TableCell>
                        <TableCell className={classes.cell}>Draaiend</TableCell>
                        <TableCell className={classes.cell}>Categorie</TableCell>
                        <TableCell className={classes.cell}>Actie</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentBoundingBoxDetails.map((detail, index) => (
                        <TableRow key={index}
                          onMouseEnter={() => setSelectedBoxIndex(index)}
                          onMouseLeave={() => setSelectedBoxIndex(null)}
                          className={classes.row}>
                          <TableCell className={classes.cell}>{`${index + 1}`}</TableCell>
                          <TableCell className={classes.cell}>
                            <FormControl variant="outlined" size="small" fullWidth>
                              <Select
                                displayEmpty
                                value={detail.type}
                                onChange={(e) => handleTypeChange(index, e)}
                                disabled={isNestingCalculated}
                                style={{ backgroundColor: colorMap[detail.type] }}
                              >
                                <MenuItem value="frames">Kozijn</MenuItem>
                                <MenuItem value="panes">Glas</MenuItem>
                                <MenuItem value="doors">Deur</MenuItem>
                                <MenuItem value="panel">Paneel</MenuItem>
                                <MenuItem value="ventilation">Ventilatierooster</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell className={classes.cell}>{Math.round(detail.width)}</TableCell>
                          <TableCell className={classes.cell}>{Math.round(detail.length)}</TableCell>
                          <TableCell className={classes.cell}>
                            <Checkbox checked={detail.turning}
                              onChange={(e) => handleTurningChange(index, e.target.checked)}
                              style={{ color: 'var(--M3-sys-light-primary, #2189CA)' }}
                            />
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <FormControl fullWidth variant="outlined" size="small" className={classes.fixedDropdown} style={{ width: '130px' }}>
                              <Select
                                displayEmpty
                                value={detail.category || ""}
                                onChange={(e) => handleCategoryChange(detail.id, e.target.value as string)}
                                renderValue={(value) => {
                                  // Assert 'value' to type 'string'
                                  const selected = value as string;
                                  if (selected === "") {
                                    return <em>Kies Categorie</em>;
                                  }
                                  return selected;
                                }}
                              >
                                <MenuItem disabled value=""><em>Kies Categorie</em></MenuItem>
                                {doorAndWindowOptions.map(option => (
                                  <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton aria-label="delete" style={{ color: 'var(--M3-sys-light-primary, #2189CA)', marginRight: '4px' }} onClick={() => handleDeleteRow(detail.id)}>
                              <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="edit" style={{ color: 'var(--M3-sys-light-primary, #2189CA)' }} onClick={() => handleEditClick(detail.type)}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                ) : (

                  <Table stickyHeader className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>Element</TableCell>
                        <TableCell className={classes.cell}>Type</TableCell>
                        <TableCell className={classes.cell}>Breedte(mm)</TableCell>
                        <TableCell className={classes.cell}>Hoogte(mm)</TableCell>
                        <TableCell className={classes.cell}>Draaiend</TableCell>
                        <TableCell className={classes.cell}>Opp (m2) </TableCell>
                        <TableCell className={classes.cell}>Categorie</TableCell>
                        <TableCell className={classes.cell}>Actie</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(() => {
                        let topLevelIndex = 0; // Initialize the top-level index
                        return nestedBoundingBoxDetails.reduce<JSX.Element[]>((acc, item) => {
                          if (!item.nested) {
                            topLevelIndex += 1; // Increment only for non-nested, top-level items
                            acc.push(...renderNestedRows(item, topLevelIndex.toString()));
                          }
                          return acc;
                        }, []);
                      })()}
                    </TableBody>

                  </Table>
                )}
              </Paper>
            </Grid>

          </Grid>

          <Box display="flex" justifyContent={isNestingCalculated ? "flex-end" : "space-between"} alignItems="center" padding={2}>
            {!isNestingCalculated && (
              <>
                <Box>
                  <Button
                    startIcon={<ZoomInIcon />}
                    onClick={toggleZoomCanvas}
                    style={{
                      marginRight: '16px',
                      borderRadius: '1px',
                      border: '1px solid var(--M3-sys-light-outline, #D8D8D8)',
                      color: 'var(--M3-sys-light-primary, #2189CA)',
                      textAlign: 'center',
                      fontFamily: '"Open Sans"',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '0.5px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    Vergrootglas
                  </Button>
                  <Button onClick={addNewRectangle} color="primary" style={{
                    marginRight: '16px',
                    borderRadius: '1px',
                    background: 'var(--M3-sys-light-primary, #2189CA)',
                    color: 'var(--M3-sys-light-on-primary, var(--common-white_states-main, #FFF))',
                    textAlign: 'center',
                    fontFamily: '"Open Sans"',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '24px',
                    letterSpacing: '0.5px',
                  }}>
                    Kozijn Toevoegen
                  </Button>

                </Box>
              </>
            )}

            <Box>
              <Button onClick={handleBackClick} color="primary" style={{
                marginRight: '16px',
                borderRadius: '1px',
                border: '1px solid var(--M3-sys-light-outline, #D8D8D8)',
                color: 'var(--M3-sys-light-primary, #2189CA)',
                textAlign: 'center',
                fontFamily: '"Open Sans"',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                backgroundColor: 'transparent',
              }}>
                Vorige
              </Button>
              {!isNestingCalculated && (
                <Button onClick={handleNextClick} disabled={isNestingCalculated} color="primary" style={{
                  marginRight: '16px',
                  borderRadius: '1px',
                  background: 'var(--M3-sys-light-primary, #2189CA)',
                  color: 'var(--M3-sys-light-on-primary, var(--common-white_states-main, #FFF))',
                  textAlign: 'center',
                  fontFamily: '"Open Sans"',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '24px',
                  letterSpacing: '0.5px',
                }}>
                  Volgende
                </Button>
              )}
              {isNestingCalculated ? (
                <>
                  <Button
                    onClick={() => downloadCsv(nestedBoundingBoxDetails, `${filenameOnly(imageUrl)}.csv`)}
                    color="primary"
                    style={{
                      marginRight: '16px',  // Adjusting spacing between buttons
                      borderRadius: '1px',
                      background: 'var(--M3-sys-light-primary, #2189CA)',
                      color: 'var(--M3-sys-light-on-primary, var(--common-white_states-main, #FFF))',
                      textAlign: 'center',
                      fontFamily: '"Open Sans"',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '0.5px',
                    }}
                  >
                    CSV Exporteren
                  </Button>
                  <Button
                    onClick={handleStartOver}
                    color="primary"
                    style={{
                      borderRadius: '1px',
                      border: '1px solid var(--M3-sys-light-outline, #D8D8D8)',
                      color: 'var(--M3-sys-light-primary, #2189CA)',
                      textAlign: 'center',
                      fontFamily: '"Open Sans"',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '0.5px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    Begin Opnieuw
                  </Button>
                </>

              ) : null}
            </Box>
          </Box>

        </Card>

      </Grid >

      <Grid item xs={1}>
        <Button variant="contained" className={classes.logoutButton} onClick={handleSignOut}>
          Uitloggen
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" style={{ marginLeft: '8px' }}>
            <path d="M0 17V0H8.5V1.88889H1.88889V15.1111H8.5V17H0ZM12.2778 13.2222L10.9792 11.8528L13.3875 9.44444H5.66667V7.55556H13.3875L10.9792 5.14722L12.2778 3.77778L17 8.5L12.2778 13.2222Z" fill="#2189CA" />
          </svg>
        </Button>
      </Grid>


    </Grid >




  );
};

export default EditCoords;