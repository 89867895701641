import React, { useState, useEffect, useContext, useRef, CSSProperties } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from "./components/helpers/authHelper"
import "./assets/scss/App.scss";
import AppContext from "./components/hooks/createContext";
import { drawCrosshair, drawLine } from "./components/helpers/scaleHelper";

import {
    TextField,
    Button,
    Grid,
    Card,
    Typography,
    Box,
} from '@material-ui/core';

import ZoomInIcon from '@material-ui/icons/ZoomIn'; // Or the specific icon you're using

import { makeStyles } from '@material-ui/core/styles';

interface Point {
    x: number;
    y: number;
}

const DefineScale = () => {
    const location = useLocation();
    const { imagePath, embeddingPath } = location.state || {};
    const [startPoint, setStartPoint] = useState<Point | null>(null);
    const [endPoint, setEndPoint] = useState<Point | null>(null);
    const isDrawing = useRef(false);
    const [centimeterValue, setCentimeterValue] = useState('');
    const [pixelLength, setPixelLength] = useState<number | null>(null);
    const [showZoomCanvas, setShowZoomCanvas] = useState(false);


    const context = useContext(AppContext);

    if (!context) {
        console.error("AppContext is not available.");
        return <div>Loading...</div>; // Or handle this case as appropriate
    }
    const { scalingFactor: [scalingFactor, setScalingFactor] } = context;

    const navigate = useNavigate();
    const handleSignOut = () => {
        signOut(navigate);
    };

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const zoomCanvasRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);

    const calculateDistance = () => {
        const canvas = canvasRef.current;
        if (startPoint && endPoint && canvas) {
            const dx = (endPoint.x - startPoint.x)
            const dy = (endPoint.y - startPoint.y)
            const length = Math.sqrt(dx * dx + dy * dy);

            setPixelLength(length);
        }
    };

    const handleMouseDown = (e: MouseEvent) => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;

            // Get the bounding rectangle of the image
            const rect = canvas.getBoundingClientRect();

            // Calculate the mouse position relative to the image
            const x = (e.clientX - rect.left) * (canvas.width / canvas.clientWidth);
            const y = (e.clientY - rect.top) * (canvas.height / canvas.clientHeight);

            // Update the startPoint state with the relative position
            setStartPoint({ x, y });
            setEndPoint(null);
        }
        // setIsDrawing(true);
        isDrawing.current = true
    };

    const handleMouseUp = (e: MouseEvent) => {
        if (canvasRef.current) {
            const img = canvasRef.current;

            // Get the bounding rectangle of the image
            const rect = img.getBoundingClientRect();

            // Calculate the mouse position relative to the image
            const x = (e.clientX - rect.left) * (img.width / img.clientWidth);
            const y = (e.clientY - rect.top) * (img.height / img.clientHeight);

            // Update the startPoint state with the relative position
            setEndPoint({ x, y });

            // Indicate that drawing has started
            // isDrawing.current = true;
        }
        isDrawing.current = false;
    };

    const handleMouseMove = (e: MouseEvent) => {
        var x = 0;
        var y = 0;
        const zoomDestSizeX = 300;
        const zoomDestSizeY = 300;
        var zoomSizeX = 0;
        var zoomSizeY = 0;
        if (canvasRef.current) {
            const img = canvasRef.current;

            zoomSizeX = img.width / 4;
            zoomSizeY = img.width / 4;

            // Get the bounding rectangle of the image
            const rect = img.getBoundingClientRect();

            // Calculate the mouse position relative to the image
            x = (e.clientX - rect.left) * (img.width / img.clientWidth);
            y = (e.clientY - rect.top) * (img.height / img.clientHeight);

        }
        if (isDrawing.current) {
            // Update the startPoint state with the relative position
            setEndPoint({ x, y });
        }
        const zoomCtx = zoomCanvasRef.current?.getContext('2d');
        const img = canvasRef.current;
        if (zoomCtx && img && zoomCanvasRef.current) {
            const rect = img.getBoundingClientRect();

            const zoomX = (x) - (zoomSizeX / 2);
            const zoomY = (y) - (zoomSizeY / 2);

            zoomCtx.fillStyle = "white";
            zoomCtx.fillRect(0, 0, zoomCanvasRef.current.width, zoomCanvasRef.current.height);
            zoomCtx.drawImage(img, zoomX, zoomY, zoomSizeX, zoomSizeY, 0, 0, zoomDestSizeX, zoomDestSizeY);

            x = (x) * (img.clientWidth / img.width);
            y = (y) * (img.clientHeight / img.height)

            if (x + zoomCanvasRef.current.width > img.clientWidth) {
                x = img.clientWidth - zoomCanvasRef.current.width
            }

            if (y + zoomCanvasRef.current.height > img.clientHeight) {
                y = img.clientHeight - zoomCanvasRef.current.height
            }

            zoomCanvasRef.current.style.top = `${y}px`;
            zoomCanvasRef.current.style.left = `${x}px`;
            zoomCanvasRef.current.style.display = "block";

            // Crosshair
            drawCrosshair(zoomCtx, zoomDestSizeX / 2, zoomDestSizeY / 2)
        }
    };

    const handleMouseLeave = () => {
        if (zoomCanvasRef.current) {
            zoomCanvasRef.current.style.display = "none";
        }
    };

    const handleCentimeterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9\b]+$/; // Regex to allow only numbers

        if (e.target.value === '' || re.test(e.target.value)) {
            setCentimeterValue(e.target.value);
        }
    };

    const handleSaveValue = () => {
        if (pixelLength && centimeterValue) {
            const centimeterValueNumeric = parseFloat(centimeterValue);
            if (centimeterValueNumeric > 0) {
                const newScalingFactor = centimeterValueNumeric / pixelLength;

                // Update scalingFactor in context
                setScalingFactor(newScalingFactor);

                // Navigate to the next component
                navigate('/samapp', {
                    state: {
                        imagePath,
                        embeddingPath,
                        scalingFactor: newScalingFactor,
                    },
                });
            } else {
                console.error('Invalid centimeter value');
            }
        } else {
            console.error(`Missing data for scaling factor calculation ${pixelLength} ${centimeterValue}`);
        }
    };

    const toggleZoomCanvas = () => {
        setShowZoomCanvas(!showZoomCanvas);
    };

    useEffect(() => {
        if (isDrawing.current && startPoint && endPoint) {
            const canvas = canvasRef.current;
            if (!canvas) return;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            if (!imageRef.current) return;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(imageRef.current, 0, 0);
            drawLine(ctx, startPoint, endPoint)
        }
        if (!isDrawing.current) {
            calculateDistance();
        }

    }, [startPoint, endPoint, isDrawing])

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const img = new Image();
        img.src = imagePath;
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            imageRef.current = img; // Store the loaded image in the ref
        };
        canvas.addEventListener('mousedown', handleMouseDown)
        canvas.addEventListener('mouseup', handleMouseUp)
        canvas.addEventListener('mouseleave', handleMouseLeave)
        canvas.addEventListener('mousemove', handleMouseMove)
        return () => {
            canvas.removeEventListener('mousedown', handleMouseDown)
            canvas.removeEventListener('mouseup', handleMouseUp)
            canvas.removeEventListener('mouseleave', handleMouseLeave)
            canvas.removeEventListener('mousemove', handleMouseMove)
        }
    }, [imagePath]);

    // Custom style that applies the zoom effect
    const contentStyle: CSSProperties = {
        display: 'flex', // Correct, 'flex' is a valid value
        flexDirection: 'column', // Ensure this matches CSSProperties' expected type
        justifyContent: 'center', // Ensure this matches CSSProperties' expected type
        alignItems: 'center', // Ensure this matches CSSProperties' expected type
        gap: '20px', // Correct, '20px' is a valid string value
        padding: '20px', // Correct, '20px' is a valid string value
        transform: `scale(1) translate(0px, 0px)`,
        transformOrigin: 'center center', // Adjust based on your needs
        transition: 'transform 0.2s', // Smooth transition for movement and zoom
    }


    const useStyles = makeStyles((theme) => ({
        logoutButton: {
            borderRadius: '1px',
            border: '1px solid #D8D8D8',
            background: '#FFF',
            color: '#2189CA',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            marginLeft: '17px',
            textTransform: 'none', // Prevent uppercase transform
            padding: '6px 12px', // Adjust padding as needed
            '& svg': { // Style for the SVG icon
                marginRight: '5px', // Space between icon and text
            },
        },
        card: {
            margin: '0',
            maxWidth: '100%',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            paddingTop: theme.spacing(6),
            paddingLeft: theme.spacing(6),

        },
        inputForm: {
            textAlign: 'left',
        },
        inputFormPadding: {
            padding: '1rem',
        }
    }));

    const classes = useStyles();

    return (
        <Grid container direction="row">
            <Grid item xs={1}>
                <img src={'../assets/skk.png'} alt="Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                <a href="https://www.pegamento.nl" target="_blank" rel="noopener noreferrer">
                    <img src={'../assets/pegamento.png'} alt="Pegamento Logo" style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '20px' }} />
                </a>
            </Grid>

            <Grid item xs={10}>
                <Card className={classes.card}>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>


                        <Grid item md={6} style={{ position: 'relative', padding: 0 }}>
                            <canvas ref={canvasRef} style={{ width: '100%', display: 'block' }}></canvas>
                            {showZoomCanvas && (
                                <canvas
                                    ref={zoomCanvasRef}
                                    width="300"
                                    height="300"
                                    style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
                                />
                            )}


                        </Grid>


                        <Grid item md={6} className={classes.inputForm}>
                            <Typography variant="h5" gutterBottom className={classes.inputFormPadding}>
                                Bepaal de schaal
                            </Typography>
                            <Typography variant="body1" className={classes.inputFormPadding}>
                                Klik op de uiterste delen van een object waarvan de echte maat bekend is en vul dan de grootte van dat object in millimeter in. Zo kan de schaal automatisch worden bepaald.                            </Typography>
                            <div className={classes.inputFormPadding}>
                                <TextField
                                    label="Voer waarde in mm in"
                                    variant="outlined"
                                    value={centimeterValue}
                                    onChange={handleCentimeterChange}
                                    style={{ marginBottom: '20px' }} // Adjust this value as needed
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: 0,
                                        step: 1
                                    }}

                                /></div>
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="space-between" padding={2}>

                        <Button
                            startIcon={<ZoomInIcon />}
                            onClick={toggleZoomCanvas}
                            style={{
                                borderRadius: '1px',
                                border: '1px solid var(--M3-sys-light-outline, #D8D8D8)',
                                color: 'var(--M3-sys-light-primary, #2189CA)',
                                textAlign: 'center',
                                fontFamily: '"Open Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '24px',
                                letterSpacing: '0.5px',
                                backgroundColor: 'transparent',
                            }}
                        >
                            Vergrootglas
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveValue}
                            style={{
                                borderRadius: '1px',
                                background: 'var(--M3-sys-light-primary, #2189CA)',
                                color: 'var(--M3-sys-light-on-primary, var(--common-white_states-main, #FFF))',
                                textAlign: 'center',
                                fontFamily: '"Open Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '24px',
                                letterSpacing: '0.5px',
                            }}
                        >
                            Volgende
                        </Button>
                    </Box>
                </Card>
            </Grid>

            <Grid item xs={1}>
                <Button variant="contained" className={classes.logoutButton} onClick={handleSignOut}>
                    Uitloggen
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" style={{ marginLeft: '8px' }}>
                        <path d="M0 17V0H8.5V1.88889H1.88889V15.1111H8.5V17H0ZM12.2778 13.2222L10.9792 11.8528L13.3875 9.44444H5.66667V7.55556H13.3875L10.9792 5.14722L12.2778 3.77778L17 8.5L12.2778 13.2222Z" fill="#2189CA" />
                    </svg>
                </Button>
            </Grid>
        </Grid>

    );
};

export default DefineScale;