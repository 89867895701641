import React, { useContext, useEffect, useState, useRef, CSSProperties } from "react";
import { useNavigate } from 'react-router-dom';
import AppContext from "./hooks/createContext";
import { ToolProps } from "./helpers/Interfaces";
import { signOut } from "./helpers/authHelper"
import * as _ from "underscore";
import {
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Paper,
  Grid,
  Card,
  IconButton,
  TextField
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const Tool = ({ handleMouseMove }: ToolProps) => {
  const {
    image: [image],
    maskImg: [maskImg, setMaskImg],
    scalingFactor: [scalingFactor]
  } = useContext(AppContext)!;

  type BoundingBox = {
    xMin: number;
    yMin: number;
    xMax: number;
    yMax: number;
  };

  const phaseHeadings = {
    initial: 'Definieer afmetingen',
    frames: 'Selecteer de kozijnen',
    panes: 'Selecteer de glasruiten',
    doors: 'Selecteer de deur',
    ventilation: 'Selecteer de ventilatieroosters',
    panel: 'Selecteer de panelen'
  };

  const phaseDesc = {
    initial: 'Klik op de blauwe plakposter/folie in het beeld om die te selecteren. Controleer de maatvoering aan de rechterkant en druk op volgende om de schaal te bepalen.',
    frames: 'Klik op de raamkozijnen in de afbeelding. Onze tool detecteert automatisch de randen voor een nauwkeurige inschatting. Later kan u de afmetingen aanpassen indien nodig',
    panes: 'Klik op de (glas)vlakken in de afbeelding. Voor blinde vlakken zonder glas kunt u later een type kiezen. Onze tool detecteert automatisch de randen voor een nauwkeurige inschatting. Later kan u de afmetingen aanpassen indien nodig',
    doors: 'Klik op de deur in de afbeelding. Onze tool detecteert automatisch de randen voor een nauwkeurige inschatting. Later kan u de afmetingen aanpassen indien nodig',
    ventilation: 'Klik op de ventilatieroosters',
    panel: 'Klik op de panelen'
  };

  type Phase = 'initial' | 'frames' | 'panes' | 'doors' | 'ventilation' | 'panel';

  type BoxType = Phase;

  interface BoundingBoxInfo {
    length: number;
    width: number;
    type: BoxType;
    turning: boolean;
  }

  const navigate = useNavigate();
  const handleSignOut = () => {
    signOut(navigate);
  };

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRef2 = useRef<HTMLCanvasElement>(null);

  const [boundingBoxList, setBoundingBoxList] = useState<Array<BoundingBox>>([]);
  const [boundingBoxDetails, setBoundingBoxDetails] = useState<Array<BoundingBoxInfo>>([]);
  const [highlightedBoxIndex, setHighlightedBoxIndex] = useState<number | null>(null);
  const [clientHeight, setclientHeight] = useState(100);

  const [currentPhase, setCurrentPhase] = useState<Phase>('initial');
  const [initialWidth, setInitialWidth] = useState<number | string>(400);
  const [initialHeight, setInitialHeight] = useState<number | string>(300);
  const [foilWidth, setFoilWidth] = useState<number>(1);
  const [foilHeight, setFoilHeight] = useState<number>(1);
  const [temporaryBoundingBox, setTemporaryBoundingBox] = useState<BoundingBox | null>(null);


  const context = useContext(AppContext);

  if (!context) {
    console.error("AppContext is not available.");
    return <div>Loading...</div>; // Or handle this case as appropriate
  }
  const { widthScale: [widthScale, setWidthScale] } = context;
  const { heightScale: [heightScale, setHeightScale] } = context;

  type ColorMap = {
    [key in BoxType]: string;
  };

  const colors: ColorMap = {
    initial: 'rgba(1, 1, 1, 1)',
    frames: 'rgba(232, 96, 96, 0.70)',
    panes: 'rgba(122, 185, 228, 0.70)',
    doors: 'rgba(87, 190, 98, 0.70)',
    ventilation: 'rgba(87, 98, 190, 0.70)',
    panel: 'rgba(190, 190, 87, 0.70)'
  };

  const [shouldFitToWidth, setShouldFitToWidth] = useState(true);
  const bodyEl = document.body;

  const canvas = canvasRef.current;
  const canvas2 = canvasRef2.current;

  const fitToPage = () => {
    if (!image) return;
    const imageAspectRatio = image.width / image.height;
    const screenAspectRatio = window.innerWidth / window.innerHeight;
    setShouldFitToWidth(imageAspectRatio > screenAspectRatio);
  };

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.target === bodyEl) {
        fitToPage();
      }
    }
  });

  const instructionImageSrc = "/assets/instruction.png";

  const handleNextPhase = () => {
    const phaseOrder: Phase[] = ['initial', 'frames', 'doors', 'panes', 'panel', 'ventilation'];
    const currentPhaseIndex = phaseOrder.indexOf(currentPhase);
    const nextIndex = currentPhaseIndex + 1;
    if (!image) return;

    if (currentPhase === 'initial' && (initialWidth === '' || initialHeight === '')) {
      alert('Voer zowel breedte als hoogte in.');
      return;
    }
    if (currentPhase === 'initial') {
      const width = parseFloat(initialWidth as string);
      const height = parseFloat(initialHeight as string);

      const widthScale = width / foilWidth;
      const heightScale = height / foilHeight;

      setWidthScale(widthScale);
      setHeightScale(heightScale);
    }

    if (nextIndex < phaseOrder.length) {
      setCurrentPhase(phaseOrder[nextIndex]);
    } else {
      navigate('/editcoords', { state: { imageUrl: image.src, boundingBoxes: mergeBoundingBoxes() } });
    }

    // Clear the canvas and remove the temporary bounding box
    if (currentPhase === 'initial' && canvas2 && canvas2.getContext) {
      const ctx2 = canvas2.getContext('2d');
      if (ctx2) {
        ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
      }
    }
    setTemporaryBoundingBox(null);
  };

  const handlePreviousPhase = () => {
    const phaseOrder: Phase[] = ['initial', 'frames', 'doors', 'panes', 'panel', 'ventilation'];
    const currentPhaseIndex = phaseOrder.indexOf(currentPhase);
    const nextIndex = currentPhaseIndex - 1;
    if (!image) return;

    if (nextIndex < phaseOrder.length && nextIndex >= 0) {
      setCurrentPhase(phaseOrder[nextIndex]);
    } else {
      navigate(-1);
    }
  };

  const handleDeleteRow = (index: number) => {
    const updatedDetails = boundingBoxDetails.filter((_, i) => i !== index);
    setBoundingBoxDetails(updatedDetails);

    const updatedBoundingBoxList = boundingBoxList.filter((_, i) => i !== index);
    setBoundingBoxList(updatedBoundingBoxList);

    redrawCanvas(updatedBoundingBoxList, updatedDetails);
  };

  const redrawCanvas = (boundingBoxes: BoundingBox[], boundingBoxDetails: BoundingBoxInfo[], tempBox: BoundingBox | null = null) => {
    if (canvas2 && canvas2.getContext) {
      const ctx2 = canvas2.getContext('2d');
      if (ctx2) {
        ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
        drawBoundingBox(ctx2, boundingBoxes, boundingBoxDetails, highlightedBoxIndex, tempBox);
      }
    }
  };
  const mergeBoundingBoxes = () => {
    return boundingBoxDetails.map((detail, index) => {
      const box = boundingBoxList[index];
      return {
        ...box,
        ...detail,
        length: detail.length,
        width: detail.width,
        id: Date.now() + "_" + Math.random().toString(36).substring(2, 11),
        panes: []
      };
    });
  };

  const handleClickOnMask = async () => {
    if (!maskImg) return;
    if (!image) return;

    if (!canvas) return;
    if (!canvas2) return;

    canvas2.width = image.width;
    canvas2.height = image.height;

    canvas.width = maskImg.width;
    canvas.height = maskImg.height;

    const ctx = canvas.getContext('2d');
    const ctx2 = canvas2.getContext('2d');
    if (!ctx) return;
    if (!ctx2) return;

    const effectiveWidthScale = widthScale || 1;
    const effectiveHeightScale = heightScale || 1;

    const img = new Image();
    img.src = maskImg.src;
    img.onload = () => {
      ctx.drawImage(img, 0, 0);

      const { width, height } = img;

      if (!canvasRef2.current) return;
      setclientHeight(canvasRef2.current.clientHeight);

      const imageData = ctx.getImageData(0, 0, width, height);

      let boundingBox = calculateBoundingBox(imageData);

      if (boundingBox.xMax > boundingBox.xMin && boundingBox.yMax > boundingBox.yMin) {
        if (currentPhase === 'initial') {
          setFoilWidth(boundingBox.xMax - boundingBox.xMin);
          setFoilHeight(boundingBox.yMax - boundingBox.yMin);

          setTemporaryBoundingBox(boundingBox); // Set the temporary bounding box
          redrawCanvas(boundingBoxList, boundingBoxDetails, boundingBox);
        }
        else {

          const length = parseFloat(((boundingBox.yMax - boundingBox.yMin) * effectiveHeightScale).toFixed(2));
          const width = parseFloat(((boundingBox.xMax - boundingBox.xMin) * effectiveWidthScale).toFixed(2));

          let updatedBoundingBoxList = [...boundingBoxList, boundingBox];
          setBoundingBoxList(updatedBoundingBoxList);

          let updatedBoundingBoxDetails = [...boundingBoxDetails, {
            length: length,
            width: width,
            type: currentPhase as BoxType,
            turning: false
          }];

          setBoundingBoxDetails(updatedBoundingBoxDetails);

          drawBoundingBox(ctx2, updatedBoundingBoxList, updatedBoundingBoxDetails, null);
        }


      }
    };
  };

  const calculateBoundingBox = (imageData: ImageData) => {
    let xMin = imageData.width, yMin = imageData.height, xMax = 0, yMax = 0;

    for (let y = 0; y < imageData.height; y++) {
      for (let x = 0; x < imageData.width; x++) {
        const alpha = imageData.data[(y * imageData.width + x) * 4 + 3];
        if (alpha > 0) {
          xMin = Math.min(xMin, x);
          yMin = Math.min(yMin, y);
          xMax = Math.max(xMax, x);
          yMax = Math.max(yMax, y);
        }
      }
    }

    if (xMin <= xMax && yMin <= yMax) {
      return { xMin, yMin, xMax, yMax };
    } else {
      return { xMin: 0, yMin: 0, xMax: 0, yMax: 0 };
    }
  };

  const drawBoundingBox = (ctx: CanvasRenderingContext2D, boundingBoxes: BoundingBox[], boundingBoxDetails: BoundingBoxInfo[], highlightedIndex: number | null, tempBox: BoundingBox | null = null) => {
    boundingBoxes.forEach((boundingBox, index) => {
      const { xMin, yMin, xMax, yMax } = boundingBox;
      const boxInfo = boundingBoxDetails[index];
      const currentColor = colors[boxInfo.type];

      ctx.lineWidth = 5;
      ctx.strokeStyle = index === highlightedIndex ? '#ffff66' : currentColor;

      ctx.beginPath();
      ctx.beginPath();
      ctx.rect(xMin, yMin, xMax - xMin, yMax - yMin);
      ctx.stroke();


    });

    if (tempBox) {
      const { xMin, yMin, xMax, yMax } = tempBox;
      ctx.lineWidth = 5;
      ctx.strokeStyle = '#ffff66';

      ctx.beginPath();
      ctx.rect(xMin, yMin, xMax - xMin, yMax - yMin);
      ctx.stroke();
    }
  };
  const handleTypeChange = (index: number, event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value;
    const updatedDetails = [...boundingBoxDetails];
    if (['frames', 'doors', 'panes', 'panel', 'ventilation'].includes(newType as string)) {
      updatedDetails[index].type = newType as Phase;
      setBoundingBoxDetails(updatedDetails);
    } else {
      console.error('Invalid type selected:', newType);
    }
  };

  const handleTurningChange = (index: number, isTurning: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDetails = [...boundingBoxDetails];
    updatedDetails[index].turning = isTurning.target.checked;
    setBoundingBoxDetails(updatedDetails);
  };

  const handleRowMouseEnter = (index: number) => {
    setHighlightedBoxIndex(index);
  };

  const handleRowMouseLeave = () => {
    setHighlightedBoxIndex(null);
  };

  const overlayStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'auto',
    pointerEvents: 'none',
  };

  useEffect(() => {
    fitToPage();
    resizeObserver.observe(bodyEl);
    return () => {
      resizeObserver.unobserve(bodyEl);
    };
  }, [image]);

  useEffect(() => {
    if (canvasRef2.current && (boundingBoxList.length > 0)) {
      const ctx2 = canvasRef2.current.getContext('2d');
      if (ctx2) {
        ctx2.clearRect(0, 0, canvasRef2.current.width, canvasRef2.current.height);
        drawBoundingBox(ctx2, boundingBoxList, boundingBoxDetails, highlightedBoxIndex, temporaryBoundingBox);
      }
    }
  }, [highlightedBoxIndex, boundingBoxDetails]);

  const imageClasses = "";
  const maskImageClasses = `opacity-40 pointer-events-none`;

  const useStyles = makeStyles((theme) => ({
    table: {
      fontSize: '0.75px',
      minWidth: 60
    },
    row: {
      height: '24px',
    },
    cell: {
      padding: '3px',
      width: '50px',
      textAlign: 'center'
    },
    logoutButton: {
      borderRadius: '1px',
      border: '1px solid #D8D8D8',
      background: '#FFF',
      color: '#2189CA',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      marginLeft: '17px',
      textTransform: 'none',
      padding: '6px 12px',
      '& svg': {
        marginRight: '5px',
      },
    },
    card: {
      margin: '0',
      maxWidth: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    },
    inputForm: {
      textAlign: 'left',
    },
    inputFormPadding: {
      padding: '1px',
    }
  }));

  const classes = useStyles();

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const re = /^[0-9\b]+$/; // Regex to allow only numbers

    if (e.target.value === '' || re.test(e.target.value)) {
      setInitialWidth(e.target.value);
    }
  };

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/; // Regex to allow only numbers

    if (e.target.value === '' || re.test(e.target.value)) {
      setInitialHeight(e.target.value);
    }
  };

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={1}>
          <img src={'../assets/skk.png'} alt="Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
          <a href="https://www.pegamento.nl" target="_blank" rel="noopener noreferrer">
            <img src={'../assets/pegamento.png'} alt="Pegamento Logo" style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '20px' }} />
          </a>
        </Grid>

        <Grid item xs={10}>
          <Card className={classes.card}>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
              <Grid item md={6} style={{ position: 'relative', padding: 0 }}>
                {image && (
                  <img
                    onMouseMove={handleMouseMove}
                    onClick={handleClickOnMask}
                    onMouseOut={() => _.defer(() => setMaskImg(null))}
                    onTouchStart={handleMouseMove}
                    src={image.src}
                    style={{ width: '100%', display: 'block' }}
                    className={imageClasses}
                  ></img>
                )}
                {maskImg && (
                  <img
                    src={maskImg.src}
                    onClick={handleClickOnMask}
                    style={{ ...overlayStyle }}
                    className={maskImageClasses}
                  ></img>
                )}
                <canvas ref={canvasRef} style={{ display: 'none' }}>Your browser does not support the canvas element.</canvas>
                <canvas ref={canvasRef2} style={{ ...overlayStyle }}></canvas>
              </Grid>
              <Grid item md={6} className={classes.inputForm}>
                <Typography variant="h5" gutterBottom className={classes.inputFormPadding}>
                  {phaseHeadings[currentPhase]}
                </Typography>
                <Typography variant="body1" className={classes.inputFormPadding}>
                  {phaseDesc[currentPhase]}
                  {currentPhase === 'initial' && (
                    <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
                      <TextField
                        label="Breedte (mm)"
                        variant="outlined"
                        value={initialWidth}
                        onChange={handleWidthChange}
                        inputProps={{ inputMode: 'numeric' }}
                        style={{ marginBottom: '1rem' }}
                      />
                      <TextField
                        label="Hoogte (mm)"
                        variant="outlined"
                        value={initialHeight}
                        onChange={handleHeightChange}
                        inputProps={{ inputMode: 'numeric' }}
                        style={{ marginBottom: '1rem' }}
                      />
                    </Box>
                  )}
                  {currentPhase !== 'initial' && (
                    <>
                      <img src={instructionImageSrc} alt="Instruction" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '20px' }} />
                      <Typography variant="body1">
                        Beweeg de muiscursor over kozijnen om ze te selecteren
                      </Typography>
                    </>
                  )}
                </Typography>
                {currentPhase !== 'initial' && (
                  <Paper style={{ maxHeight: `${0.50 * clientHeight}px`, overflow: 'auto', marginRight: '10px' }}>
                    <Table stickyHeader className={classes.table}>
                      <TableHead>
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>Element</TableCell>
                          <TableCell className={classes.cell}>Type</TableCell>
                          <TableCell className={classes.cell}>Breedte (mm)</TableCell>
                          <TableCell className={classes.cell}>Hoogte (mm)</TableCell>
                          <TableCell className={classes.cell}>Draaiend</TableCell>
                          <TableCell className={classes.cell}>Actie</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {boundingBoxDetails.map((detail, index) => (
                          <TableRow key={index} className={classes.row}
                            onMouseEnter={() => handleRowMouseEnter(index)}
                            onMouseLeave={handleRowMouseLeave}>
                            <TableCell className={classes.cell}>{`${index + 1}`}</TableCell>
                            <TableCell className={classes.cell}>
                              <FormControl variant="outlined" size="small" fullWidth>
                                <Select
                                  value={detail.type}
                                  onChange={(e) => handleTypeChange(index, e)}
                                  className={classes.inputForm}
                                >
                                  <MenuItem value="frames">Kozijn</MenuItem>
                                  <MenuItem value="panes">Glas</MenuItem>
                                  <MenuItem value="doors">Deur</MenuItem>
                                  <MenuItem value="ventilation">Ventilatierooster</MenuItem>
                                  <MenuItem value="panel">Paneel</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell className={classes.cell}>{Math.round(detail.width)}</TableCell>
                            <TableCell className={classes.cell}>{Math.round(detail.length)}</TableCell>
                            <TableCell className={classes.cell}>
                              <Checkbox
                                checked={detail.turning}
                                onChange={(e) => handleTurningChange(index, e)}
                                style={{ color: 'var(--M3-sys-light-primary, #2189CA)' }}
                              />
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <IconButton aria-label="delete" style={{ color: 'var(--M3-sys-light-primary, #2189CA)' }} onClick={() => handleDeleteRow(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                )}
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end" padding={2}>
              <Button
                variant="contained"
                onClick={handlePreviousPhase}
                style={{
                  marginRight: '8px',
                  borderRadius: '1px',
                  border: '1px solid var(--M3-sys-light-outline, #D8D8D8)',
                  color: 'var(--M3-sys-light-primary, #2189CA)',
                  textAlign: 'center',
                  fontFamily: '"Open Sans"',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '24px',
                  letterSpacing: '0.5px',
                  backgroundColor: 'transparent',
                }}
              >
                Vorige
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextPhase}
                style={{
                  borderRadius: '1px',
                  background: 'var(--M3-sys-light-primary, #2189CA)',
                  color: 'var(--M3-sys-light-on-primary, var(--common-white_states-main, #FFF))',
                  textAlign: 'center',
                  fontFamily: '"Open Sans"',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '24px',
                  letterSpacing: '0.5px',
                }}
              >
                Volgende
              </Button>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={1}>
          <Button variant="contained" className={classes.logoutButton} onClick={handleSignOut}>
            Uitloggen
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" style={{ marginLeft: '8px' }}>
              <path d="M0 17V0H8.5V1.88889H1.88889V15.1111H8.5V17H0ZM12.2778 13.2222L10.9792 11.8528L13.3875 9.44444H5.66667V7.55556H13.3875L10.9792 5.14722L12.2778 3.77778L17 8.5L12.2778 13.2222Z" fill="#2189CA" />
            </svg>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Tool;
