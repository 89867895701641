import React from 'react'; // Import React, standard practice for TypeScript with JSX
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Login from './Login';
import SamApp from './SamApp';
import UploadPage from './UploadPage';
import DefineScale from './DefineScale';
import EditCoords from './EditCoords';
import { RequireToken } from './Auth';
import AppContextProvider from "./components/hooks/context"


const App = () => {
  const flexCenterClasses = "flex items-center justify-center";
  return (
    <div className="App h-full">
      <div className={`${flexCenterClasses} w-full h-full`}>
        <div style={{ padding: '4rem' }} className={`${flexCenterClasses} relative w-[85%] h-[85%]`}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/samapp" element={
              <RequireToken>
                <AppContextProvider>
                  <SamApp />
                </AppContextProvider>
              </RequireToken>
            } />
            <Route path="/uploadpage" element={
              <RequireToken>
                <AppContextProvider>
                  <UploadPage />
                </AppContextProvider>
              </RequireToken>
            } />
            <Route path="/definescale" element={
              <RequireToken>
                <AppContextProvider>
                  <DefineScale />
                </AppContextProvider>
              </RequireToken>
            } />
            <Route path="/editcoords" element={
              <RequireToken>
                <AppContextProvider>
                  <EditCoords />
                </AppContextProvider>
              </RequireToken>
            } />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;