import { useNavigate } from "react-router"
import { fetchToken, setToken } from "./Auth"
import { signOut, getApiUrl } from "./components/helpers/authHelper"
import React, { useEffect, useState } from "react";
import axios from "axios"
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  CircularProgress,
} from '@material-ui/core';


export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = getApiUrl();

  const navigate = useNavigate()
  const handleSignOut = () => {
    signOut(navigate);
  };

  const login = () => {
    if (!username || !password) {
      setErrorMessage("Email and password cannot be empty.");
      return;
    }
    setIsLoading(true);
    console.log('API URL:', apiUrl);

    axios.post(`${apiUrl}/auth/login`, {
      username,
      password
    })
      .then(response => {
        console.log(response.data.token, 'response.data.token')
        if (response.data.token) {
          setToken(response.data.token);
          navigate("/uploadpage");
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : "An unexpected error occurred.";
        setErrorMessage(message);
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={6} style={{ padding: 20, marginTop: 30 }}>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', margin: '20px' }}>
          <img src={'../assets/skk.png'} alt="Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
          <a href="https://www.pegamento.nl" target="_blank" rel="noopener noreferrer">
            <img src={'../assets/pegamento.png'} alt="Pegamento Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
          </a>
        </div>

        <Typography component="h1" variant="h5">
          SKK Digitaal inmeten prototype
        </Typography>
        <Box mt={2}>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" style={{ color: 'var(--M3-sys-light-primary, #2189CA)' }} />}
            label="Onthoud me"
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={login}
            disabled={isLoading}
            style={{
              borderRadius: '1px',
              background: 'var(--M3-sys-light-primary, #2189CA)',
              color: 'var(--M3-sys-light-on-primary, var(--common-white_states-main, #FFF))',
              textAlign: 'center',
              fontFamily: '"Open Sans"',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
              letterSpacing: '0.5px',
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Inloggen'}
          </Button>
        </Box>

      </Paper>
    </Container >
  );
}