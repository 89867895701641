import React, { ChangeEvent, useState, DragEvent } from 'react'; // Import useState along with ChangeEvent
import { useNavigate } from 'react-router-dom';
import { fetchToken } from "./Auth"
import { filenameOnly, getApiUrl } from "./components/helpers/authHelper"
import { Paper, Button, Typography, Box, CircularProgress } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const UploadPage = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Initialize loading state
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState('');

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setActiveFile(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      setActiveFile(file);
    }
  };

  const setActiveFile = (file: File) => {
    const newFileName = `${getTimeString()}_${file.name}`.replace(/\s/g, '_');

    // Create a new File object with the new name
    const fileWithNewName = new File([file], newFileName, {
      type: file.type,
      lastModified: file.lastModified,
    });
    setSelectedFile(fileWithNewName); // Set the selected file
    setFileName(fileWithNewName.name);
  }

  const getTimeString = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, add 1 to make it human-readable
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}_${hours}:${minutes}`;
  };

  const handleUploadSubmit = async () => {
    if (selectedFile) {
      const formData = new FormData();

      formData.append("image", selectedFile);

      setIsLoading(true);
      try {
        const token = fetchToken();
        const response = await fetch(`${getApiUrl()}/embedding/generate-embedding`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.detail || "An unexpected error occurred.";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        const filename = data.filename;
        const imagePath = `/assets/data/${filenameOnly(fileName)}/${selectedFile.name}`;
        const npyFilePath = `/assets/data/${filenameOnly(fileName)}/${filename}`;

        navigate('/samapp', { state: { imagePath: imagePath, embeddingPath: npyFilePath } });
      } catch (error) {
        const message = (error instanceof Error && error.message) || "An unexpected error occurred.";
        setErrorMessage(message);
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("No file selected.");
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">

      <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', margin: '20px' }}>
          <img src={'../assets/skk.png'} alt="Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
          <a href="https://www.pegamento.nl" target="_blank" rel="noopener noreferrer">
            <img src={'../assets/pegamento.png'} alt="Pegamento Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
          </a>
        </div>
        <Typography variant="h5">Upload uw bestand</Typography>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Box
          border="1px dashed grey"
          padding="20px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <CloudUploadIcon style={{ fontSize: '50px' }} />
          <Typography>Sleep bestand hier</Typography>
          <Typography variant="caption">Ondersteunde formaten: PNG, JPG</Typography>
          {fileName && <Typography variant="body2">{fileName}</Typography>}
          <Typography variant="caption">OR</Typography>
          <Button variant="contained" component="label" disabled={isLoading} style={{
            marginRight: '8px',
            borderRadius: '1px',
            border: '1px solid var(--M3-sys-light-outline, #D8D8D8)',
            color: 'var(--M3-sys-light-primary, #2189CA)',
            textAlign: 'center',
            fontFamily: '"Open Sans"',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            backgroundColor: 'transparent',
          }}>
            Bladeren
            <input type="file" accept="image/png, image/jpeg" hidden onChange={handleImageUpload} />
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button variant="text" onClick={() => navigate(-1)}>Annuleren</Button>
          <Button variant="contained" color="primary" onClick={handleUploadSubmit} disabled={isLoading || !selectedFile} style={{
            borderRadius: '1px',
            background: 'var(--M3-sys-light-primary, #2189CA)',
            color: 'var(--M3-sys-light-on-primary, var(--common-white_states-main, #FFF))',
            textAlign: 'center',
            fontFamily: '"Open Sans"',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.5px',
          }}>
            {isLoading ? <CircularProgress size={24} /> : 'Analyseren'}
          </Button>
        </Box>
        <Typography variant="caption" style={{ textAlign: 'center', marginTop: '8px' }}>
          Dit duurt ongeveer 1 minuut in het prototype
        </Typography>
      </Paper>
    </Box>
  );
};

export default UploadPage;